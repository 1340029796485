export default {
    // General
    general_cancel: 'Cancelar',
    general_accept: 'Aceptar',
    general_go_home: 'Regresar a inicio',
    general_dialog_error: 'Error',
    general_dialog_success: 'Éxito',
    general_no_internet: 'No es posible conectar con el servidor. Por favor verifique su conexión a internet.',
    general_auth_failed: 'Nombre de usuario o contraseña incorrecta. Por favor verifique sus credenciales.',
    general_not_found: 'Objeto no encontrado.',
    general_user_exists: 'El usuario ya existe.',
    general_new: 'Nuevo',
    general_edit: 'Editar',
    general_search: 'Buscar',
    general_save: 'Guardar',
    general_no_data: 'Sin datos',
    general_no_results: 'No hay resultados',
    general_of_total: 'de',
    general_rows_per_page: 'Filas por página',
    general_all_rows: 'Todas las filas',
    general_actions: 'Comportamiento',
    general_delete: 'Borrar',
    general_dialog_confirm: 'Confirmar',
    general_dialog_confirm_delete: '¿Estás seguro de que quieres eliminar este elemento?',
    general_results: 'Resultados',
    general_print: 'Impresión',
    general_upload_image: 'Arrastra la imagen aquí para subir',
    general_upload_max: 'Tamaño máximo 20mb.',
    general_decline: 'Disminución',
    general_all: 'Todos',
    general_send: 'Enviar',
    general_view_details: 'Haz click para ver más detalles',
    general_dialog_error_uploading_image: 'El tamaño máximo es de 20mb.',
    general_subscribe: 'Suscribirse',
    general_cancel_subscription: 'Cancelar suscripción',
    general_change_payment_method: 'Cambiar método de pago',
    general_login: 'Iniciar sesión',
    general_pay: 'Pagar',
    general_cancellation: 'Cancelación disponible a elección del comprador',
    general_subscribe_btn: '¡Suscríbete!',
    general_know_more: 'Know more',
  
  
    // Header
    header_menu_logout: 'Logout',
    header_menu_about: 'About us',
    header_menu_business_solutions: 'Business solutions',
    header_menu_join: 'Join us',
    header_menu_contact: 'Contact',
    header_menu_login: 'Login',
    header_menu_process: 'Our process',
    header_menu_portfolio: 'Portfolio',
    mission_vision: 'Our mission and vision',
    company: 'Our company',
    offices: 'Our offices',
    team: 'Our team',
    commitment: 'Our commitment',
    services: 'Our services',
    clients: 'Our clients',
  
    // Footer
    footer_about: 'About us',
    footer_solutions: 'Business solutions',
    footer_process: 'Our process',
    footer_portfolio: 'Portfolio',
    footer_contact: 'Contact',
    footer_join: 'Join us',
    footer_text: 'All trademarks, logos, and brands are property of their respective owners. All company, product and service names used in this website are for indentification pruposes only. Responsible Disclosure Policy for Security Researchers.',
  
    // Fields


    // Buttons
    button_know_more: 'Know more',
    button_apply: 'Apply now',
    button_work: 'Work with us',
    button_portfolio: 'View our portfolio',
    button_contact: 'Contact',

  
    // Landing
    landing_title: 'Building the next generation of business',
    landing_subtitle: 'We partner with the most qualified people and businesses to accelerate their growth.',
    landing_view_portfolio: 'View our portfolio',
    landing_about_us_text: "We're a startup accelerator firm based in Monterrey, Mexico. We offer mentorship, guidance, and resources to accelerate the growth of your business and investments.",
    landing_business_text: "We provide custom business solutions to help our client's project to succeed through a variety of innovative processes that are not only high on functionality but are also low on cost.",

    // Not found
    not_found_title: 'Error 404 - Página no encontrada',
    not_found_description: 'No pudimos encontrar la página que estabas buscando.',

  
    // About
    about_title1: 'We are DriveOp',
    about_title2: 'Innovative Leaders',
    about_title3: 'Integrity Business Solutions',
    about_title4: 'Business Leaders',
    about_text1: 'An acceleration firm that develops ventures and businesses through project & objectives management, applied marketing, software engineering, and business intelligence practices.',
    about_text2: "We are leaders in creating more effective processes, products, and approaches for our investors. We're constantly seeking new ways to create new innovative paths and opportunities as we grow in the industry making our projects stand out from the crowd.",
    about_text3: 'Our integrity is reflected in the way we do business in every single aspect of the company. We support our investors and team members following the same set of principles and values in every professional relationship, in order to have long-term success.',
    about_text4: 'We inspire, communicate and supervise others by providing meaningful direction and advice for our partners to encourage them to meet their goals and creating a positive environment that conduces to productivity and efficiency.',
    about_drive: 'Drive',
    about_innovation: 'Innovation',
    about_integrity: 'Integrity',
    about_leadership: 'Leadership',
    about_apply_button: 'Apply now',
    about_mission: 'Mission',
    about_mission_text: 'We are determinated to discover the most talented entrepreneurs and investors in the world and provide them mentorship, advice, resources, and everything they need to build a successful venture and succeed globally.',
    about_vision: 'Vision',
    about_vision_text: 'We are motivated by the idea of creating new business opportunities in Mexico and all around the world. With that idea in mind, we are committed to be the most important business acceleration firm and guide new companies to achieve their goals and also help them reach the true potential of their people.',
    about_passion: 'Our passion drives us',
    about_passion_title: 'Long-term shared purposes',
    about_passion_text: "We acknowledge the importance of strong partnerships through all aspects of our company that's why we are strongly committed to helping our investors, employees and entrepreneurs startups to succeed globally.",
    about_future: 'We strive for the future',
    about_future_title: 'Constantly improving',
    about_future_text: 'DriveOp continually moves forward leading businesses of all shapes and sizes all to succeed by focusing on consistent improvements and processes that strive for the future.',
    about_value: 'We increase value',
    about_value_title: 'Increase your business value',
    about_value_text: 'DriveOp helps its entrepreneurs and partners to make their operating systems more efficient, as well as their business model.',
    about_map_title: 'Venture growth and coorporate solutions across the globe',
    about_map_text: 'DriveOp has its headquarters in Monterrey, Mexico and São Paulo, Brazil.',
    about_team_intro: 'Our team',
    about_team_title: 'Our most important investment',
    about_team_subtitle: 'Let us grow your talent',
    about_team_text: "Our team is our most important investment. We're all out of the box thinkers that discover opportunities in every challenge that comes our way.",
    about_team_work: 'Work with us',
    about_projects: 'Projects',
    about_offices: 'Offices',
    about_countries: 'Countries',
    mexico: 'Mexico',
    brasil: 'Brasil',
    spain: 'Spain',
    nigeria: 'Nigeria',
    usa: 'USA',
    canada: 'Canada',
    hong_kong: 'Hong Kong',
    panama: 'Panama',
    canada: 'Canada',
    monterrey: 'Monterrey, Mexico',
    sao_pablo: 'São Paulo, Brazil',
    about_apply_title: 'Apply to DriveOp today',
    about_apply_text: 'Apply as part of our team, as an investor or as a business who wants to grow.',
 
    // Portfolio
    portfolio_title: 'Our Portfolio',
    portfolio_text: 'Since 2010, we partner up with the most daring entrepreneurs and executives. Take a look at our portfolio companies',
    portfolio_subtitle: 'Trusted by over 2,000 leading companies',

    // Profile
    profile_title_1: 'What is a',
    profile_title_2: 'Main tasks',
    profile_title_3: 'Experience at DriveOp',
    profile_title_4: 'Hobbies',

    // Process
    process_title: 'Our process',
    process_subtitle: 'Driving business through innovative solutions',
    process_text: 'At DriveOp, we strive to partner up with the best entrepreneurs and business leaders, to design and implement an acceleration program based on growth potential, timing, and how it can change the world as we know it.',
    
    evaluate_title: 'Evaluate (or Assess) Synergies',
    evaluate_subtitle: 'Due Diligence',
    evaluate_text: 'We analyze the feasibility and vialibility of a business through an enhanced Due Diligence process',

    collaboration_title: 'Collaboration',
    collaboration_subtitle: 'Unique Differentiation',
    collaboration_text: "We pull together the most effective solutions to join forces and combine our value proposition with the entrepreneur's leadership.",

    strategy_title: 'Bespoke Strategy',
    strategy_subtitle: 'Business Assessment',
    strategy_text: 'We prepare and scheme a set of related measures with the objective of aiming to long-term profitability.',

    partnership_title: 'Partnership with Shared Services',
    partnership_subtitle: 'Business Plan',
    partnership_text: 'Committed to success, we create a complete, adapting, and in-depth business action plan to guarantee success for the company.',


    commercial_title: 'Commercial Strategies',
    commercial_text: 'Improve profitability.',
    
    corporate_title: 'Corporate services',
    corporate_text: 'Consolidate enterprise-wide needed services through specialized knowledge.',
    
    business_title: 'Business solutions',
    business_text: 'Drive businesses through industry solutions.',
    
    development_title: 'Software development',
    development_text: 'Redefine how businesses can innovate and transform (or compete) in a digital world.',
    
    marketing_title: 'Marketing & branding',
    marketing_text: 'Creative and cost-effective solutions to maximize brand performance.',
    
    innovation_title: 'Growth & innovation',
    innovation_text: 'Power innovation and performance over time',
    
    since: 'Since',
    departments: 'Departments',
    // Solutions
    solutions_title: 'Growth & Innovation',
    solutions_header_text: "Strategic growth and innovation are imperative in our business model. We're always improving the way we do things and enhance the projects of our partners.",
    solutions_year_1_title: 'We empower entrepreneurs and help companies reach their true potential',
    solutions_year_1_text: 'Being founded as an ACCELERATION firm, DriveOp developed the best practices for operations and finances in private equity, which were later transformed to successfully attend corporate solutions for expansion and project finances.',
    solutions_year_2_title: 'Created to assist multiple types of businesses',
    solutions_year_2_text: 'DriveOp business solutions were created to assist multiple types of transactions, such as soft-landings, deal advisory, and capital-stack development.',
    solutions_year_3_title: 'We keep innovating and growing',
    solutions_year_3_text: 'Our team has advised and led projects with a combined worth of +$1B USD with all kinds of companies.',
    solutions_services_title: 'We empower people and organizations to achieve more',
    solutions_services_text: 'With in-depth knowledge on a wide range of industries, our team executes all necessary steps towards attaining the most favorable solution for each firm.',
    solutions_map_title: 'We invest in diverse businesses all across the world',
    solutions_map_text: 'Headquartered in Monterrey and with offices in São Paulo, our firm combines transactional expertise and creativity to provide each company the means to succeed.',
    image1_title: 'Our financial advisory services include:',
    image1_bullet1: 'Corporate development & investment package.',
    image1_bullet2: 'Restructuring and financing search for high leveraged transactions.',
    image1_bullet3: 'Sell-side support, due diligence and valuation.',
    image1_bullet4: 'Staple finance.',
    image2_title: 'Our business services include:',
    image2_bullet1: 'Expansion and LATAM soft landing services.',
    image2_bullet2: 'Market intelligence and competition.',
    image2_bullet3: 'Deal advisory and hands-on execution.',
    image3_title: 'Our legal service and advisory include:',
    image3_bullet1: 'Corporate governance.',
    image3_bullet2: 'Corporate reorganizations.',
    image3_bullet3: 'Private M&A.',
    image3_bullet4: 'Finance & credit facilities.',
    image3_bullet5: 'LATAM soft landing procedures.',
    image3_bullet6: 'Startup legal procedures.',
    solutions_services_title2: 'Make your startup to succeed globally',
    solutions_services_text2: 'DriveOp Business Solutions, leverages the expertise of its Accelerator to provide a fully integrated solution involving technology, cybersecurity, and communications experts for increased efficency in privacy, information management and project delivery purposes.',
    solutions_clients_title: 'Diverse entrepreneurs all across the world',
    solutions_clients_text: 'Our firm is a one-stop-shop partner that allows sponsors to focus on their core business while being provided seamless, transparent and prompt transaction processes. After DriveOp and sponsors prove a lasting synergy and entrusted confidence, our firm is open to consider direct private equity investments to further increase our participation in strategic industries.',
    solutions_contact_text: 'Due to the nature of our business, our clientele is not limited to any particular industry and scope. For further inquiries please contact us providing company name and brief project details.',
    // Contact us
    contact_title: 'Contact us',
    contact_header_text: 'Accelerate your business, invest in a project or join our team.',
    contact_header_text2: 'Contact us for more information.',
    contact_location_title: 'Our location',
    contact_detail_title: 'Contact detail',
    contact_text: "or fill out this form, we'll quickly get back to you",

    // Join us
    join_title: 'Be part of our team',
    join_header_text: 'Accelerate your business, invest in a project or join our team. Contact us for more information.',
    join_apply: 'Apply',

  
  }
  