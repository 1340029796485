import 'normalize.css'
import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import VueResource from 'vue-resource'
import Api from './api'
import GlobalMixins from './mixins/GlobalMixins'
import VueMoment from 'vue-moment'
import './ml'
// import * as Sentry from '@sentry/browser'
import firebase from 'firebase/app'
import 'firebase/auth'
import VueTheMask from 'vue-the-mask'
import VueAnimated from '@codekraft-studio/vue-animated'

let firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
}

firebase.initializeApp(firebaseConfig)

// if (process.env.NODE_ENV !== 'development') {
//   Sentry.init({
//     dsn: process.env.VUE_APP_SENTRY_DNS,
//     integrations: [new Sentry.Integrations.Vue({
//       Vue,
//       attachProps: true
//     })]
//   })
// }

// Moment language
const moment = require('moment')
let lang = ''
switch ((navigator.language || navigator.userLanguage).slice(0, 2)) {
  case 'es':
    lang = 'es'
    break

  case 'pt':
    lang = 'pt'
    break

  default:
    break
}

if (lang) {
  require('moment/locale/' + lang)
}

Vue.use(Api)
Vue.use(VueResource)
Vue.use(VueAnimated)
Vue.use(VueMoment, {
  moment
})

Vue.use(VueTheMask)

// HTTP Requests Interceptors
Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push((request, next) => {
  request.headers.set('Content-Type', 'application/json')
  if (!request.headers.map.Authorization) {
    request.headers.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
  }
  if (!request.headers.map['Content-Type']) {
    request.headers.set('Content-Type', 'application/json')
  }
  next()
})

Vue.mixin(GlobalMixins)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
