import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  dark: false,
  theme: {
    themes: {
      light: {
        primary: process.env.VUE_APP_COLOR_PRIMARY,
        secondary: process.env.VUE_APP_COLOR_SECONDARY,
        accent: process.env.VUE_APP_COLOR_ACCENT,
        error: process.env.VUE_APP_COLOR_ERROR
      }
    }
  }
})