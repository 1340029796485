export default {
  // General
  general_cancel: 'Cancelar',
  general_accept: 'Aceptar',
  general_go_home: 'Regresar a inicio',
  general_dialog_error: 'Error',
  general_dialog_success: 'Éxito',
  general_no_internet: 'No es posible conectar con el servidor. Por favor verifique su conexión a internet.',
  general_auth_failed: 'Nombre de usuario o contraseña incorrecta. Por favor verifique sus credenciales.',
  general_not_found: 'Objeto no encontrado.',
  general_user_exists: 'El usuario ya existe.',
  general_new: 'Nuevo',
  general_edit: 'Editar',
  general_search: 'Buscar',
  general_save: 'Guardar',
  general_no_data: 'Sin datos',
  general_no_results: 'No hay resultados',
  general_of_total: 'de',
  general_rows_per_page: 'Filas por página',
  general_all_rows: 'Todas las filas',
  general_actions: 'Comportamiento',
  general_delete: 'Borrar',
  general_dialog_confirm: 'Confirmar',
  general_dialog_confirm_delete: '¿Estás seguro de que quieres eliminar este elemento?',
  general_results: 'Resultados',
  general_print: 'Impresión',
  general_upload_image: 'Arrastra la imagen aquí para subir',
  general_upload_max: 'Tamaño máximo 20mb.',
  general_decline: 'Disminución',
  general_all: 'Todos',
  general_send: 'Enviar',
  general_view_details: 'Haz click para ver más detalles',
  general_dialog_error_uploading_image: 'El tamaño máximo es de 20mb.',
  general_subscribe: 'Suscribirse',
  general_cancel_subscription: 'Cancelar suscripción',
  general_change_payment_method: 'Cambiar método de pago',
  general_login: 'Iniciar sesión',
  general_pay: 'Pagar',
  general_cancellation: 'Cancelación disponible a elección del comprador',
  general_subscribe_btn: '¡Suscríbete!',
  general_know_more: 'Know more',

  // Rules
  rules_required_field: 'Campo requerido.',
  rules_invalid_email: 'Email inválido.',
  rules_min_length_8: 'La longitud mínima es 8',
  rules_invalid_rfc: 'RFC no válido',
  rules_invalid_phone: 'Numero de telefono invalido.',
  rules_invalid_curp: 'CURP no válido.',
  rules_invalid_nss: 'NSS inválido.',
  rules_invalid_phone_ext: 'Número de extensión de teléfono no válido.',
  rules_invalid_zip_code: 'Código postal inválido.',
  rules_invalid_credit_card: 'Número de tarjeta inválido.',
  rules_invalid_date: 'Fecha inválida.',
  rules_invalid_cvc: 'CVC inválido.',

  // Login
  login_title: 'Iniciar sesión',
  login_with_facebook: 'Iniciar sesión con facebook',
  login_with_google: 'Iniciar sesión con google',
  register_with_facebook: 'Registrarse con facebook',
  register_with_google: 'Registrarse con google',

  // Header
  header_menu_logout: 'Cerrar sesión',
  header_menu_about: '¿Quiénes somos?',
  header_menu_box: 'Ruffus BOX',
  header_menu_login: 'Iniciar sesión',
  header_menu_profile: 'Perfil',
  header_menu_myprofile: 'Mi perfil',
  header_menu_history: 'Historia',
  header_menu_contact: 'Contacto',
  header_menu_brackets: 'Brackets',
  header_menu_content: 'Contenido',
  header_menu_tickets: 'Tickets',
  header_menu_event: 'Evento',
  header_menu_cosplay: 'Cosplay',
  header_menu_layout: 'Escenario',
  header_menu_profile_history:'Mis pedidos',
  header_menu_profile_subscription:'Suscripción',

  // Footer
  footer_about: 'About us',
  footer_process: 'Our process',
  footer_portfolio: 'Portfolio',
  footer_contact: 'Contact',
  footer_join: 'Join us',
  footer_newsletter_success: 'Has quedado suscrito a nuestro newsletter.',

  // Fields
  fields_first_name: 'Nombre',
  fields_last_name: 'Apellidos',
  fields_full_name: 'Nombre completo',
  fields_email: 'Correo electrónico',
  fields_phone_home: 'Número de teléfono',
  fields_password: 'Contraseña',
  fields_birthdate: 'Fecha de nacimiento',
  fields_phone_cellphone: 'Número Celular',
  fields_gender: 'Género',
  fields_phone: 'Teléfono',
  fields_cardholder: 'Nombre en tarjeta',
  fields_cardnumber: 'Número de tarjeta',
  fields_card_expiration: 'Fecha de expiración',
  fields_card_expiration_month: 'Mes',
  fields_card_expiration_year: 'Año',
  fields_cvc: 'CVC',
  fields_street: 'Calle',
  fields_street_and_number: 'Calle y número',
  fields_neighborhood: 'Colonia',
  fields_city: 'Ciudad',
  fields_state: 'Estado',
  fields_zip_code: 'Código postal',
  fields_street_number_ext: 'Núm. exterior',
  fields_street_number_int: 'Núm. interior',
  fields_same_address: 'Misma que dirección de envío.',
  fields_pet_name: 'Nombre de tus mascotas',
  fields_pet_size: 'Tamaño',
  fields_pet_gender: 'Género',
  fields_order: 'Número de orden',
  fields_delivery_date: 'Fecha estimada de entrega',
  fields_payment_method: 'Método de pago',
  fields_next_payment: 'Fecha de renovación',
  fields_last_payment: 'Fecha de último cobro',
  fields_price: 'Precio',
  fields_status_subscription: 'Estatus de la suscripción',
  fields_tracking: 'Número de rastreo',
  fields_review: 'Calificación',
  fields_newsletter: 'Suscribete a nuestro newsletter',


  // Landing
  landing_banner: 'Building the next generation of business',
  landing_subtitle: 'We partner with the best people, opportunities, and business to accelerate their growth',
  landing_how_1_title: 'Suscríbete',
  landing_how_1_text: 'Subscripción mensual, rápida y 100% segura',
  landing_how_2_title: 'Recibe tu Ruffus Box',
  landing_how_2_text: 'Recibe tu primer pedido en menos de 2 semanas. Envíos a todo México',
  landing_how_3_title: 'Disfruta',
  landing_how_3_text: 'Disfruta con tu perro los premios y más beneficios',
  landing_companies_title: 'Trusted by over 2,000 leading companies',
  landing_footer_text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius, nemo nostrum laudantium voluptatibus repellat fugit quibusdam quam aspernatur minus totam deserunt reprehenderit magni unde laborum beatae? Ipsum vero officia incidunt.',
  landing_footer_title: 'Interested in applying?',
  landing_footer_text: 'Apply as parto of our team, as an investor or as a business who wants to grow.',
  landing_footer_button: 'Apply now',

  // Not found
  not_found_title: 'Error 404 - Página no encontrada',
  not_found_description: 'No pudimos encontrar la página que estabas buscando.',

  // Profile
  profile_account: 'Perfil',
  profile_box: 'Mis Ruffus Box',
  profile_address: 'Dirección de envío',
  profile_suscription: 'Suscripción',

  // About
  about_banner: 'We are DriveOp',
  about_subtitle: 'An acceleration firm that develops ventures and businesses through project & objectives managment, applied marketing, software engineering and business intelligence practices.',
  about_drive: 'Drive',
  about_innovation: 'Innovation',
  about_integrity: 'Integrity',
  about_leadership: 'Leadership',
  about_apply_button: 'Apply now',
  about_mission: 'Mission',
  about_vision: 'Vision',
  // Ruffus Box
  box_products: 'Productos',

}
