import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from './store'
import CRUD from '@/CRUD'

// Pages
const Landing = () => import('./views/Landing')
const About = () => import('./views/About')
const Portfolio = () => import('./views/Portfolio')
const Profile = () => import('./views/Profile')
const Process = () => import('./views/Process')
const Solutions = () => import('./views/Solutions')
const ContactUs = () => import('./views/ContactUs')
const Join = () => import('./views/Join')
const Login = () => import('./views/Login')
const NotFound = () => import('./views/NotFound')
const RecoverPassword = () => import('./views/RecoverPassword')
const Terms = () => import('./views/Terms')

// Dynamic Entities
const DynamicCRUD = () => import('./components/DynamicCRUD')

// Functions
function capitalize (string) {
  return string[0].toUpperCase() + string.slice(1)
}

let routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/process',
    name: 'Process',
    component: Process,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: Solutions,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: ContactUs,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/restaurar/:token',
    name: 'Cambiar Contraseña',
    component: RecoverPassword
  },
  {
    path: '/iniciar_sesion',
    name: 'Login',
    component: Login,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/terminos',
    name: 'Terms',
    component: Terms,
    meta: {
      footerApp: false,
      hideHeader: false
    }
  },
  {
    path: '/no_encontrado',
    component: NotFound
  },
  {
    path: '*',
    component: NotFound
  }
]
CRUD.map(crud => {
  routes.push({
    path: '/' + crud.entity,
    name: capitalize(crud.entity),
    component: DynamicCRUD
  })
})

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: routes
})

// Router Login Guard
// router.beforeEach((to, from, next) => {
//   if (!store.getters.auth.access_token && to.path !== '/login') {
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router
