<template>
  <v-app>
    <div v-if="!g_isLoading" >
      <global-header v-if="!$route.meta.hideHeader"></global-header>

      <v-content class="pa-0">
        <router-view :key="$route.fullPath" />
      </v-content>

      <global-footer></global-footer>
    </div>
  </v-app>
</template>

<script>
const GlobalFooter = () => import('./views/Footer')
const GlobalHeader = () => import('./views/Header')
import CSSGenerator from '@/mixins/CSSGenerator'

export default {
  name: 'App',
  mixins: [CSSGenerator],
  components: {
    GlobalFooter,
    GlobalHeader
  },
  metaInfo: {
    title: 'DriveOp',
    meta: [
      { vmid: 'description', name: 'description', content: 'Building the next generation of business. We partner with the best people, opportunities and businesses to accelerate their growth.' },
      { vmid: 'og:title', property: 'og:title', content: 'DriveOp' },
      { vmid: 'og:url', property: 'og:url', content: 'https://www.driveop.com' },
      { vmid: 'og:image', property: 'og:image', content: 'https://www.driveop.com/img/driveop_card_09_03_2020.png' },
      { vmid: 'og:description', property: 'og:description', content: 'Building the next generation of business. We partner with the best people, opportunities and businesses to accelerate their growth.' },
      { vmid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: 'DriveOp' },
      { vmid: 'twitter:title', property: 'twitter:title', content: 'DriveOp' },
      { vmid: 'twitter:image', property: 'twitter:image', content: 'https://www.driveop.com/img/driveop_card_09_03_2020.png' },
      { vmid: 'twitter:description', property: 'twitter:description', content: 'Building the next generation of business. We partner with the best people, opportunities and businesses to accelerate their growth.' }
    ],
    link: [
      { vmid: 'canonical', rel: 'canonical', href: 'https://www.ruffusbox.com' }
    ]
  },
  mounted () {
    if (typeof (Event) === 'function') {
      document.dispatchEvent(new Event('render-event'))
    }

    // if (this.g_auth.access_token) {
    //   this.$authGetUser()
    // }
  }
}
</script>

<style>

/**********/
/* Fonts  */
/**********/

@font-face {
    font-family: 'Venera';
    src: url('./assets/fonts/Venera-500.otf');
}

@font-face {
    font-family: 'Venera-Bold';
    src: url('./assets/fonts/Venera-900.otf');
}

@font-face {
    font-family: 'Antenna';
    src: url('./assets/fonts/Antenna-Medium.otf');
}

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/AvenirLTStd-Light.otf');
}

/************/
/* Defaults */
/************/
::-webkit-scrollbar {
  background: #FFFFFF;
  height: 8px;
  width: 10px
}

::-webkit-scrollbar-thumb {
  border: none;
  -webkit-box-shadow:none;
  box-shadow:none;
  background:#DDDDDD;
  min-height:40px
}

:hover::-webkit-scrollbar-thumb {
  background:#BDBDBD
}

::-webkit-scrollbar-thumb:hover {
  background:#AAAAAA
}

::-webkit-scrollbar-thumb:active{
  background:#989898
}


.application, .application.theme--light, html {
  /*font-family: Microgramma;*/
  background: #ffffff;
}

.venera {
  font-family: 'Venera';
}

.venera-bold {
  font-family: 'Venera-Bold';
}

.antenna {
  font-family: 'Antenna';
}

.avenir {
  font-family: 'Avenir';
}
h1, h2, h3 {
  font-size: inherit;
  font-weight: inherit;
}

p {
  font-size: inherit;
  font-weight: inherit;
  font-family: 'Avenir-Medium';
  color: '#4E5056';
}



.banner-title {
  font-family: 'Antenna';
  font-size: 37px;
  line-height: 1.0;
}

.accent--text {
  color: #4E5056;
}
.about-title {
  font-family: 'Nunito-Black';
  font-size: 64px;
  color: #fff;
  line-height: 1.0;
}

.banner-title-light {
  font-family: 'Nunito-Light';
  font-size: 48px;
  line-height: 1.0;
}
.subtitle {
  font-family: 'Avenir-Roman';
  font-size: 18px;
  color: #4E5056
}


.btn-text {
  font-family: 'Venera-Bold';
  font-size: 12px;
  color: rgb(0, 203, 196) !important;
}

.btn-text-white {
  font-family: 'Venera-Bold';
  font-size: 12px;
  color: #ffffff !important;
}

.btn-header {
  background-color: #1a3c4f !important;
  border: 1px solid rgb(0, 203, 196) !important;
  border-radius: 20px !important;
}

.btn-secondary {
  background-color: rgb(0, 203, 196) !important;
  border: 1px solid rgb(0, 203, 196) !important;
  border-radius: 20px !important;
}

.driveop-divider {
  border-color: #00cbc4 !important;
}

.image-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
}

.divider {
  border-width: 3px !important;
  border-color: rgb(2,149,153) !important;
}

.hover:hover {
    cursor: pointer !important;
}

.hover:hover p {
    color: #0bd1cb !important;
}

.hover-title:hover {
    cursor: pointer !important;
}

.hover-title:hover h3 {
    color: #0bd1cb !important;
}

.hover:hover img {
    filter: brightness(1) !important;
}

/***********/
/* Helpers */
/***********/

.subscription-stepper .v-stepper__step__step {
  display: none;
}
.hidden-shadow {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.height-auto {
  height: auto !important;
}

.self-start {
  align-self: start;
}

.lowercase {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.hidden-shadow, .hidden-shadow .v-input__slot {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}


.hidden-border .v-input__slot, .hidden-border .v-input__slot::before, .hidden-border .v-input__slot::after {
  border-style: none !important;
  border-color: transparent !important;
  border-width: 0px !important;
}

.hidden-border .v-text-field__slot input {
  text-align: center;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.clickable {
  cursor: pointer !important;
}


.pr-10 {
  padding-right: 96px;
}

.pr-20 {
  padding-right: 192px;
}

.pl-10 {
  padding-left: 96px;
}


.primary-color {
  color: #1a3c4f !important;
}

.secondary-color {
  color: #0bd1cb !important;
}

.third-color {
  color: #029599;
}

.aqua-background {
  background-color: rgb(2,149,153)
}


.clear-background {
  background-color: #f1f1f1 !important;
}

.white-background {
  background-color: #FFF
}

.primary-background {
  background-color: rgb(26,60,79);
}

.secondary-background {
  background-color: rgb(2,149,153) !important;
}

.grey-background {
  background-color: #e9e8e3;
}

.green-background {
  background-color: #029599 !important;
}

.driveop-list {
  background-color: rgba(2,139,143,.95) !important;
  width: 320px;
  height: 796px !important;
}


.center {
  display: flex;
  justify-content: center;
}


.blue-overlay {
    outline: 230px solid rgba(26,60,79,0.4) !important;
    outline-offset: -230px !important;
    overflow: hidden !important;
    position: relative !important;
    width: 100% !important;
}


/**********/
/* Images */
/**********/


.profile-background {
  /* background-image: url("../img/images/offices.jpg"); */
  background-image: url("./assets/img/team/Arturo2.png");
  height: 400px;
  background-size: cover;
}

.hover-img {
  background-color: rgb(26,60,79);
  opacity: 0.83;
  font-size: 28px;
  color: #454545;
  align-items: center;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/**********/
/*  Map   */
/**********/


.distribution-map {
  position: relative;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
}

.distribution-map  > img {
width: 100%;
position: relative;
margin: 0;
padding: 0;
}

.distribution-map .map-point {
    cursor: default;
    outline: none;
    z-index: 0;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgba(26, 26, 26, 0.85);
    border: 1px solid rgba(26, 26, 26, 0.85);
}

 .mobile {
  width: 10px;
  height: 10px;
}


   
  

/*********/
/* Fixes */
/*********/
.hidden-image-input .v-input__slot {
  display: none;
}

.vc-swatches {
  width: 100% !important;
}

.v-toolbar__items .v-btn--active:before, .v-toolbar__items .v-btn:focus:before, .v-toolbar__items .v-btn:hover:before {
  background-color: transparent !important;
}

.v-toolbar__items .v-btn--active .v-btn__content, .v-toolbar__items .v-btn:focus .v-btn__content, .v-toolbar__items .v-btn:hover .v-btn__content {
  color: #f4364c !important;
}


.v-toolbar__content {
  max-width: 1200px;
  margin: auto;
  padding: 0px;
}

.v-input .v-input__slot {
  min-height: 30px !important;
  max-height: 30px !important;
}



.v-input.v-textarea .v-input__slot {
  min-height: inherit !important;
  max-height: inherit !important;
}

.v-expansion-panel-header__icon {
  display: none !important;
}

.v-expansion-panel-header{
  margin: 0px !important;
  padding: 0px !important;
}

.v-stepper__header {
  height: 100% !important;
}

.inactive-step {
  border: 3px solid #f93549;
}

.v-expansion-panel-content__wrap  {
  padding: 0 !important
}

.title {
  font-size: 34px;
}


/***********/
/* xsOnly */
/***********/
@media screen and (max-width: 600px) {
  .centered-div {
    display:flex;
    justify-content: center;
  }

  .title {
  font-size: 28px;
}

.hover-img {
  font-size: 14px;
}

}

/***********/
/* smAndUp */
/***********/
@media screen and (min-width: 600px) {
}

/***********/
/* mdAndUp */
/***********/
@media screen and (min-width: 870px) {
}

/***********/
/* lgAndUp */
/***********/
@media screen and (min-width: 1050px) {

}

/***********/
/* xlgAndUp */
/***********/
@media screen and (min-width: 1600px) {

}

.header-btn .v-toolbar__items .v-btn--active .v-btn__content, .v-toolbar__items .v-btn:focus .v-btn__content, .v-toolbar__items .v-btn:hover .v-btn__content {
  font-weight: bold;
  font-size: 16px;
}

.profile-btn.v-btn.v-btn--outline {
  background: transparent !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.profile-btn {
  padding: 20px !important;
}

.grecaptcha-badge {
  display: none !important;
 }

</style>
