import Vue from 'vue'

import Auth from '@/api/Auth.js'
import CRUDS from '@/CRUD'
import Users from '@/api/Users.js'


// Functions
function capitalize (string) {
  return string[0].toUpperCase() + string.slice(1)
}

function pluralize (string) {
  return string.endsWith('y') ? string.slice(0, -1) + 'ies' : string + 's'
}

// Dinamically create routes for CRUD
let methods = {}

CRUDS.map(CRUD => {
  let postAction = (payload, callback, errorHandler) => {
    return Vue.http.post(CRUD.security.post + '/' + CRUD.entity, payload).then(callback, errorHandler)
  }

  let getAction = (id, callback, errorHandler) => {
    return Vue.http.get(CRUD.security.get + '/' + CRUD.entity + '/' + id).then(callback, errorHandler)
  }

  let listAction = (callback, errorHandler) => {
    return Vue.http.get(CRUD.security.list + '/' + pluralize(CRUD.entity)).then(callback, errorHandler)
  }

  let updateAction = (id, payload, callback, errorHandler) => {
    return Vue.http.put(CRUD.security.put + '/' + CRUD.entity + '/' + id, payload).then(callback, errorHandler)
  }

  let deleteAction = (id, callback, errorHandler) => {
    return Vue.http.delete(CRUD.security.delete + '/' + CRUD.entity + '/' + id).then(callback, errorHandler)
  }

  methods['create' + capitalize(CRUD.entity)] = postAction
  methods['get' + capitalize(CRUD.entity)] = getAction
  methods['list' + capitalize(CRUD.entity) + 's'] = listAction
  methods['update' + capitalize(CRUD.entity)] = updateAction
  methods['delete' + capitalize(CRUD.entity)] = deleteAction
})

export default {
  ...Auth,
  ...Users,
  ...methods,
  install (Vue) {
    Vue.prototype.$api = Vue.api = this
  }
}
